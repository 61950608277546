
/* HTML: <div class="loader"></div> */
.loader {
  width: 16px;
  display: inline-block;
  aspect-ratio: 1;
  background: 
    linear-gradient(#9990 calc(1*100%/6),#999 0 calc(3*100%/6),#9990 0) left   bottom,
    linear-gradient(#6660 calc(2*100%/6),#666 0 calc(4*100%/6),#6660 0) center bottom,
    linear-gradient(#3330 calc(3*100%/6),#333 0 calc(5*100%/6),#3330 0) right  bottom;
  background-size: 20% 600%;
  background-repeat: no-repeat;
  animation: l3 2s infinite linear;
}
@keyframes l3 {
    100% {background-position: left top,center top,right top }
}