

.autosize {
  min-height: 24px;
  height: 100%;
  width: 100%;
  border: none;
  resize: none;
  overflow: auto;
  outline: none;
  font-size: 14px;
  line-height: 20px;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  padding: 0;
  background: none;
  box-shadow: none;
  box-sizing: content-box;
  textarea{
    height: 100%;
  }
}
  