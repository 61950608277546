.item {
    .title {
        overflow: hidden;
        white-space: nowrap;
        word-break: break-all;
        width: 210px;
        text-overflow: ellipsis;
    }
}
.wrap {
    height: auto;
    opacity: 1;
    transform: none;
    transform-origin: 50% 50% 0px;
}
